.imageArrow {
  transform: rotate(90deg);
}

.imageArrowMobile {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
}

.imageDots .btn-default {
  transform: rotate(0deg);
  border: none;
  cursor: pointer;
  background-color: #fff4f4;
}

p {
  font-size: 14px;
}

.row_border_bottom {
  border-bottom: 1px solid lightgray;
}

.row_border {
  border: 1px solid lightgray;
}

.number__Timesheet {
  border: 1px solid black;
  border-radius: 50px;
  padding: 0px 10px 0px 10px;
}

.bi-three-dots-vertical {
  color: #222222;
}

.viewTimesheetEllipses .nav-link {
  padding-right: 0;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip::after {
  content: attr(title);
  visibility: hidden;
}
.tooltip:hover::after {
  visibility: visible;
}
