.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 15px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "+";
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
}

.bootstrap-demo .toggle.expanded::after {
  content: "-";
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}

.custom-dropdown .select__menu {
  max-height: 200px; /* Set maximum height for the dropdown */
  overflow-y: auto;  /* Enable vertical scrolling */
  z-index: 999;      /* Ensure the dropdown appears above other elements */
}

.custom-dropdown .select__menu-list {
  max-height: inherit; /* Match the height of the menu */
}

@media screen and (max-width: 850px) and (min-width: 450px) {
  .custom-dropdown .select__menu {
    max-height: 300px; /* Adjust maximum height for smaller screens */
  }
}

@media screen and (max-width: 450px) and (min-width: 380px) {
  .custom-dropdown .select__menu {
    max-height: 400px; /* Adjust maximum height for smaller screens */
  }
}

@media screen and (max-width: 380px) {
  .custom-dropdown .select__menu {
    max-height: 280px; /* Adjust maximum height for smaller screens */
  }
}

.display-selected-filter-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}