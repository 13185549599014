.mobile__dialog .MuiDialog-paper {
  margin: 0 !important;
}

.mobile__dialog .MuiDialog-paperScrollPaper {
  max-height: 100% !important;
}

.mobile__dialog .MuiDialog-paperFullWidth {
  width: 100%;
}

.row__colour {
  background-color: #f7f7f7;
}
