.btn-default {
  border: 1px solid grey;
}

.btn {
  border-radius: inherit;
}
.center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-ftc-border-primary {
  border-color: #a50808;
  color: #a50808;

  &:hover {
    border-color: #ca3535;
    color: #ca3535;
  }

  &:active {
    border-color: #ca3535;
    color: #ca3535;
  }
}

.btn-ftc-border-secondary {
  border-color: #dcdddd;
  color: grey;

  &:hover {
    border-color: rgb(22, 22, 22);
    color: rgb(65, 65, 65);
  }

  &:active {
    border-color: rgb(22, 22, 22);
    color: rgb(65, 65, 65);
  }
}

.btn-ftc-primary-light {
  background-color: #fff4f4;
  color: #a50808;
  border-color: lightgrey;
}

.btn-ftc-primary-light-border {
  background-color: #fff4f4;
  color: #a50808;
  border-color: lightgrey;
  min-width: 97px;
  &:hover {
    color: #a50808;
    border-color: #a50808;
  }
}

.btn-ftc-primary {
  background-color: #a50808;
  border-color: #a50808;
  color: white;
  outline: none;

  &:hover {
    background-color: #c93b3b;
    border-color: #c93b3b;
    color: white;
  }

  &:active {
    background-color: #a50808;
    border-color: #a50808;
    color: white;
    outline: 0;
  }

  &:disabled {
    background-color: #b4b4b4;
    color: white;
    border-color: #b4b4b4;
  }
}

.btn-default {
  &:hover {
    border-color: #ca3535;
    background-color: #fff4f4;
  }

  &:active {
    border-color: #ca3535;
    background-color: #fff4f4;
  }

  &:disabled {
    background-color: #b4b4b4;
    color: white;
    border-color: #b4b4b4;
  }
}

// .dropDownButton{
//   border-bottom
// }

.btn-border-round {
  border-radius: 25px;
}

.btn:active {
  outline: none;
}

.btn:focus {
  box-shadow: none;
}

.dropleft .dropdown-toggle::before {
  // vertical-align: 0;
  display: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00ba4f;
  border-color: #00ba4f;
}

// Reimbursement
.back-btn {
  cursor: pointer;
}

.cross-btn {
  cursor: pointer;
}

// Mobile Buttons View
.mobile-btn-view {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
}

.mobile-btn-view > * {
  flex-grow: 1; /* Each child will take equal space */
}

.export-btn {
  background-color: #fff4f4;
  color: #a50808;
  border-color: lightgrey;
  &:hover {
    color: #a50808;
    border-color: #a50808;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.filter-btn {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.clear-all-btn-default {
  text-decoration: underline !important;
  margin: 0;
}