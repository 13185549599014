.ftc-logo-24{
    width: 24px;
    height: 24px;
}

.ftc-logo-28{
    width: 28px;
    height: 28px;
}

.ftc-logo-30{
    width: 30px;
    height: 30px;
}

.ftc-logo-20{
    width: 20px;
    height: 20px;
}

.ftc-logo-15{
    width: 15px;
    height: 15px;
}

.ftc-logo-40{
    width: 40px;
    height: 40px;
}

.ftc-sidebar-header-logo{
    width: 176.5px;
    height: 40px;
}


