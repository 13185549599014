.icon_arrow {
  transform: rotate(270deg);
  width: 16px;
  height: 16px;
}

.hr__row {
  margin: 0;
}

.small__profile__pic {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #d9d9d94d;
  z-index: 10;
}

.modal__image {
  object-fit: contain;
}
