a:hover {
  text-decoration: none !important;
}

.margin-page {
  min-height: 90%; /* Fallback for browsers do NOT support vh unit */
  min-height: 90vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.containers {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.07);
}

.btn-light {
  border-color: #b4b4b4 !important;
}

.primary-color {
  color: #a50808;
}

.row {
  padding: 10px;
}

.google__logo__size {
  width: 1.2rem;
}

.google__logo__text {
  margin-left: 0.5rem;
}

.primary__text__size {
  font-size: 0.875rem;
}

.image__column__padding {
  padding: 0 !important;
}

.ftw__logo {
  width: 100px;
  height: 100px;
}

.mobile__view__margin {
  margin: auto;
}

.header__label {
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
}

/* MEDIA QUERIES  */

/* Phone View */
@media (max-width: 37.5em) {
  .crop {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }

  .image__view {
    /* height: 300px; */
    margin: -145px 0 0 0;
  }

  .mobile__view__padding {
    padding: 0;
  }

  .mobile__view__margin {
    margin-top: -25%;
  }
} ;
