.active-ellipse {
  display: inherit;
  margin-right: 2px;
  width: 8px;
  height: 8px;
  background-color: #00ba4f;
  border-radius: 50px;
}

.inactive-ellipse {
  display: inherit;
  margin-right: 2px;
  width: 8px;
  height: 8px;
  background-color: #b4b4b4;
  border-radius: 50px;
}

.pending-ellipse {
  display: inherit;
  margin-right: 2px;
  width: 8px;
  height: 8px;
  background-color: #ffbd41;
  border-radius: 50px;
}

.deny-ellipse {
  display: inherit;
  margin-right: 2px;
  width: 8px;
  height: 8px;
  background-color: #ff7c7c;
  border-radius: 50px;
}
