.rdt_TableBody{
    border-left: 1px solid #dcdddd;
    border-bottom: 1px solid #dcdddd;
    border-right: 1px solid #dcdddd;
}

.rdt_Pagination{
    border-top-style: none !important;
    margin-left: 20px
}

.offline__table .rdt_TableRow{
    background-color: #fffaf2 !important;
}


.MuiStepConnector-lineHorizontal {
    margin-top: -13px !important;
    margin-right: -11px !important;
    margin-left: -23px !important;
}
