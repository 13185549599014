/* .form-group {
  margin-bottom: 0 !important;
} */

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.ellipse__mobile {
  margin-right: -13px;
  margin-top: -45px;
}
