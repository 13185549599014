//Colors
$color-primary: #a50808;
$color-light-primary: #f5e7e8;

//Font Color
$text-primary: #000000;
$text-secondary: #b4b4b4;
$text-grey: #696969;

//Label Color
$label-green: #00ba4f;
$label-yellow: #ffbd41;
$label-red: #ff4646;

//Sidebar
$sidebar-width: 50rem;
