.group_filter {
  border-top: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
}

.modal__row {
  height: 100%;
  padding: 0;
}

.modal__mobile__scroll {
  overflow: scroll;
}

.modal__mobile__view {
  width: max-content;
  list-style: none;
  padding: 0;
  display: flex;
  cursor: pointer;
}

/* .modal__list__padding {
  margin-left: 14px;
} */

.modal-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bi-three-dots-vertical {
  cursor: pointer;
}

.mobile__timesheet__dialog .MuiDialog-paper {
  margin: 0 !important;
}

.mobile__timesheet__dialog .MuiDialog-paperFullWidth {
  width: inherit !important;
}

.mobile__timesheet__dialog .MuiDialog-scrollPaper {
  align-items: flex-end;
}

.sync-icon {
  width: 24px;
  height: 24px;
}

.sync-icon-spin {
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.copy-icon {
  width: 24px;
  height: 24px;
}

.discount-icon {
  width: 24px;
  object-fit: contain;
  vertical-align: bottom;
}

.clear-all-btn {
  border-right: 1px solid #dfdfdd !important;
  text-decoration: underline !important;
}

.custom-row-height {
  height: 90px;
}

.flex-time {
  height: 70px;
}

.flex-pay-hours {
  height: 60px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.alertStyle {
  padding: 5px !important;
}
