.disabled {
  background-color: #f1f1f1;
  color: #8e949e;
}

.time-picker-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.col-time-picker {
  min-width: 300px;
}

.rounded {
  border-radius: 5px;
}
