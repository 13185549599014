.MuiStepIcon-root.MuiStepIcon-completed {
  color: #a50808 !important;
}

.MuiStepIcon-active {
  color: #a50808 !important;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #a50808 !important;
}

.MuiButton-label {
  color: #a50808 !important;
}

.MuiPickersClockPointer-thumb {
  border-color: #a50808 !important;
}

.MuiStepLabel-root {
  display: contents !important;
}

.MuiStepLabel-iconContainer {
  padding-bottom: 8px !important;
}

.MuiStepIcon-root {
  margin: auto !important;
}

.MuiStepper-horizontal {
  width: 30rem !important;
  margin: auto !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.ellipses:hover {
  background-color: lightskyblue;
}

.column__width {
  padding: 0 !important;
}

.payment__modal {
}

@media only screen and (max-width: 812px) {
  .MuiStepper-horizontal {
    width: 22rem !important;
    margin: auto !important;
  }
}
