.txt-primary {
  color: $color-primary;
}

.txt-black-primary {
  color: $text-primary;
}

.txt-light-secondary {
  color: $text-secondary;
}

.txt-grey {
  color: $text-grey;
}

.form-label {
  font-size: 14px;
}

.font-24 {
  font-size: 24px;
}

.MuiTypography-body1 {
  font-size: 16px !important;
}

.css-2b097c-container {
  font-size: 16px !important;
}

.zmfZH,
.gdRcOB {
  color: #b4b4b4 !important;
}

.landingPage__dropdown .dropdown-item {
  color: #a50808 !important;

  &:active {
    color: white !important;
  }

  &:focus {
    color: #a50808 !important;
  }
}
