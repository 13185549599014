.MuiStepLabel-root {
  display: contents;
  padding-left: 16px !important;
}

.MuiStepLabel-iconContainer {
  padding-bottom: 8px;
}

.MuiStepIcon-root {
  margin: auto;
}

.MuiStepLabel-label.MuiStepLabel-active{
  color: #a50808 !important;
}

.MuiStepConnector-completed .MuiStepConnector-line{
  border-color: #a50808 !important;
}

.MuiStepConnector-active .MuiStepConnector-line{
  border-color: #a50808 !important;
}