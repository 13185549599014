// .custom-container {
//   margin-left: calc($sidebar-width - 100%);
// }

.image-top--2{
  margin-top: -2px;
}

.content-vcenter {
  margin-top: 15%;
}

.content-vcenter-2 {
  margin-top: 50%;
}
.container-top-7 {
  margin-top: 7%;
}
.container-top-8 {
  margin-top: 8%;
}

.container-top-12 {
  margin-top: 27vh;
}

.container-top-5 {
  margin-top: 5%;
}

.container-top-20 {
  margin-top: 20%;
}

.container-top-30{
  margin-top: 30%;
}

.container-top-50 {
  margin-top: 50%;
}

.embed-responsive-1by1::before {
  padding-top: 150%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 4px;
}

.embed-responsive-4by3::before {
  padding-top: 60%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding-left: 6px !important;
  padding-right: 4px !important;
  // margin-bottom: -70px;
}


.embed-responsive{
  padding-bottom: 100vh !important;
}

.embed-responsive-1by1::before{
  border: none !important;
}

.embed-responsive-4by3::before{
  border: none !important;
}

.client__embed-form .embed-responsive{
  padding-bottom: 0vh !important;
}