.sticky-dashboard-header {
  position: fixed;
  width: 81.5%;
  padding-top: 20px;
  padding-bottom: 0;
  top: 0;
  left: 19.2%;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid lightgrey;
}

.sticky-page-header {
  position: fixed;
  width: 100%;
  padding-bottom: 0;
  top: 0;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid lightgrey;
}

.sticky-dashboard-header-mobile {
  position: fixed;
  width: 95%;
  padding-bottom: 9px;
  top: 0;
  z-index: 10;
  background-color: white;
  padding-top: 18px;
}
.svgHover {
  cursor: pointer;
}
.tooltip-container {
  width: 60%;
}
