.manageStep .MuiStepper-horizontal {
  width: 18rem !important;
  margin: auto !important;
}

.profile__picture {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.avatar__image {
  background-color: #d9d9d94d;
  border-radius: 50%;
}

.image__wrapper {
  background-color: #d9d9d94d;
  cursor: pointer;
}
