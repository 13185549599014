body {
    font-family: arial;
    font-size: 14px;      
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(182, 179, 179) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgb(182, 179, 179)  !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: rgb(182, 179, 179) !important;
}

.MuiInput-input::placeholder{
   color: rgb(161, 160, 160)  !important;
}