.modal-container {
  height: 100vh;
}

.modal-content {
  height: 80%;
  overflow-y: auto;
}

.modal__ul {
  list-style: none;
  padding: 0;
  cursor: pointer;
  position: sticky;
  top: 0;
}

.modal__li {
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  border-bottom: 1px solid lightgrey;

  // &:not(:last-child) {
  //   border-bottom: 1px solid lightgrey;
  // }
}

.modal__Col_3_Padding {
  padding: 0;
}

.mb__modal__li {
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  padding-left: 0.6rem;
  border-bottom: 1px solid lightgray;
}

.modal__li:hover,
.mb__modal__li:hover {
  background-color: #fff4f4;
  color: #a50808;
}

.mb__modal_filter_active {
  background-color: #fff4f4;
  color: #a50808;
}

.modal__row {
  height: 100%;
  padding: 0;
}

@media screen and (max-height: 823px) {
  .modal__row {
    height: 100%;
    .border__right {
      height: 80.5vh;
    }
  }
}
@media screen and (max-height: 736px) {
  .modal__row {
    height: 100%;
    .border__right {
      height: 78.2vh;
    }
  }
}
@media screen and (max-height: 667px) {
  .modal__row {
    height: 100%;
    .border__right {
      height: 76vh;
    }
  }
}

@media screen and (max-width: 767px) {
  .modal-content {
    height: 100%;
  }

  .modal-dialog {
    margin: 0;
  }
  .modal-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 10 !important;
    background-color: white !important;
  }
  .modal-footer {
    position: sticky !important;
    bottom: 0px !important;
    background: white !important;
    z-index: 10 !important;
    padding-bottom: 30px !important;
  }
  .modal-filters {
    position: sticky !important;
    top: 0px !important;
  }
  .modal-dialog-scrollable {
    max-height: 100%;
  }
}
