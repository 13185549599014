.MuiTablePagination-toolbar{
    margin-right: -18px !important;
}

.MuiTablePagination-selectRoot {  
    margin-right: 15px !important; 
}

.mobile__pagination .makeStyles-root-1 {    
    margin-left: 6px !important;
}

.mobile__pagination .MuiIconButton-root{
    padding: 7px !important;
}

.mobile__pagination .MuiTablePagination-toolbar{
  margin-left: -12px !important;
  margin-right: 0px !important;
}

.mobile__pagination .MuiToolbar-gutters{
  padding-left: initial !important;
  padding-right: initial !important;
}

.mobile__pagination .MuiTablePagination-selectRoot{
  margin-left: 0px;
}