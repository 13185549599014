.svg__icon__size {
  width: 28px;
  height: 28px;
}

.svg__icon__size__arrow__close {
  width: 15px;
  height: 15px;
}

.svg__icon__size__arrow__logout {
  width: 15px;
  height: 15px;
}

.svg__sub-icon__size {
  width: 24px;
  height: 24px;
}

.sidebar__logo__margin {
  margin-top: 12rem;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0px;
  background: white;
}
.version {
  width: 100%;
  background: white;
  padding-bottom: 25px;
  color: #b4b4b4;
  padding-left: 20px;
  padding-top: 16px;
}
.sidebar-image {
  width: 208px;
  height: fit-content;
  margin: 60px 16px 10px;
  padding: 10px 19px 10px 12px;
  background-color: #f5f5f5;
}

.user__icon__size {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 50%;
}

.DesktopView {
  top: 0;
  width: 17%;
  border-right: 1px solid lightgrey;
}

.MobileView {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}

.MobileView .sidebar__width {
  width: 92% !important;
}

.desktop__nav__icon__width {
  width: 12rem;
}

.mobile__nav__icon__width {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  background-color: white;
}

.user-info {
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-around;
  border-style: solid;
  border-color: #dcdddd;
  border-width: 2px 0;
  user-select: none;
}
.sidebar-username {
  outline: none;
}
.sidebar-username:hover {
  border-right: 4px solid #a50808;
  cursor: pointer;
}

.username {
  flex: 2;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  z-index: 1;
  right: 0;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #000000;
}

@media screen and (max-width: 1235px) {
  .desktop__nav__icon__width {
    width: 90%;
  }
}

/* only for tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .MobileView {
    width: 30%;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .sidebar__logo__margin {
    margin-top: 0;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    bottom: 0px;
    background: white;
  }
}

/* mobile Screens */
@media screen and (min-width: 0px) and (max-width: 767px) {
  .SidebarHide {
    left: -100%;
  }
  .sidebar__logo__margin {
    margin-top: 0;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    bottom: 0px;
    background: white;
  }
  .version {
    width: 100%;
    background: white;
    padding-bottom: 145px;
    color: #b4b4b4;
    padding-left: 20px;
    padding-top: 16px;
  }
}
.sticky-top-logo {
  position: sticky;
  position: -webkit-sticky;
  padding-top: 10px;
  top: 0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
