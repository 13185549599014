.upload-container {
  max-width: 600px;
  margin: 40px auto;
  text-align: center;
}

.receipts-box {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0px;
  display: flex;
  justify-content: center;
  .list-group-horizontal {
    gap: 10px;
  }
}

.file-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
}

.list-group-item {
  border: 0;
  background-color: #fff4f4;
}

.remove-icon {
  border: none;
  background: none;
  color: #a50808;
  padding: 0;
  margin: 0 10px;
  font-weight: bold;
  cursor: pointer;
}

.plus-icon {
  font-size: 24px;
  border: none;
  background: #fff4f4;
  cursor: pointer;
  color: #a50808;
  font-weight: bold;
}

.btn-link:hover {
  color: #a50808;
}

.receipts-list {
  gap: 40px;
  .react-pdf__Page__canvas {
    width: 70px !important;
    height: 80px !important;
  }

  img {
    padding: 20px 20px 0px 20px;
    max-width: 150px;
  }

  .react-pdf__Document {
    padding: 20px 20px 0px 20px;
  }
}

.receipt-item.active {
  background: #fff4f4;
  border: 1px solid #a50808;
}

.receipt-container {
  width: 470px;
  max-height: 395px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}

.receipt-page {
  /* Adjust the width to match the container width */
  width: 470px;
}

.no-receipt-found {
  width: 100%;
  max-height: 400px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h6 {
    color: red;
  }

  .row {
    width: 100%;
  }

  .upload-receipt-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.active-receipt {
  max-height: 400px;
  border: 1px solid #d7d7d7;
  overflow: auto;
  margin: 20px 65px 10px 65px;
  padding: 4px;
  @media only screen and (max-width: 600px) {
    margin: 20px 0px 10px 0px;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px 0px 10px 0px;
  }
}

.active-receipt-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .react-pdf__Page__canvas {
    @media only screen and (max-width: 600px) {
      width: 300px !important;
      height: 664px !important;
    }
    @media only screen and (max-width: 768px) {
      width: 335px !important;
      height: 664px !important;
    }
  }
}

.react-pdf__Page__annotations {
  display: none;
}

/* Define the scrollbar styles */
.custom-scrollbar {
  overflow-x: scroll;
  white-space: nowrap;
}

/* Style the scrollbar track */
.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Style the scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

/* Change the thumb color on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
