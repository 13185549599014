.MuiFormControl-root, .MuiInputBase-fullWidth {    
    border: 1px solid lightgrey !important;  
}

.MuiInputBase-root{
    padding: 4px !important;
}

.MuiInputBase-input {
    margin: 0 0 0 10px !important;    
}

.MuiInput-underline:after{
    border-bottom: 2px solid black !important;
}

.form-control{
    border-radius: 0;
}

.form-control:focus {    
    border-color: lightgrey;
    border-bottom: 2px solid black !important;
    outline-style: none;
    box-shadow: none;

}

.form-control:hover{
    border-color: lightgrey;
    border-bottom: 2px solid black !important;    
}

.col-custom-padding{
    padding: 10px 16px 10px 16px;
    border: solid 1px #e0e0e0;
    border-radius: 10px;
}