.badge-pill{
    border: 1px solid #000000;
}


.offline__text{  
  color: #ffbd41;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
}