/** notification design **/
  .Toastify__toast-container {
    // width: 278px;
    .Toastify__toast--dark {
      background-color:  #231f20;
      border-radius: 4px;
    //   font-family: $font-family-base;
    }
    .Toastify__close-button {
      opacity: 1;
      margin-top: -2px;
      svg {
        width: 20px;
        height: 20px;
        margin-top: -12px;
      }
    }
  }