.dropdown-menu.show{
    box-shadow: 0 5px 12px 0 lightgrey;
    border: solid 1px #dcdddd;
    background-color: #ffffff;
    border-bottom: 2px solid black;
}

.dropdown-item{
    padding: 0.45rem 1.5rem;
}

.dropdown-menu{
    font-size: 14px;
}

.selected-sidebar-item {
    background: #fff4f4;
    border-right: 4px solid #a50808;
    cursor: pointer;
    text-decoration: none;
}

.selected-sidebar-submenu-item {
    background: #fff4f4;
    border-right: 4px solid #a50808;
    text-decoration: none;
    cursor: pointer;
}

.landingPage__dropdown{
    margin-top: -37px !important;
    margin-right: -13px !important;
}

// .landingPage__dropdown .dropdown-item{
//     padding: 0.45rem 2.9rem 0.4rem 1.2rem;
// }