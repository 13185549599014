.ftc-radiobutton{
    position: absolute;
    width: 24px;
    height: 24px;
    color: black;
    border: 1px solid gray;
    border-radius: 25px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 7px;
      width: 8px;
      height: 14px;
      border-style: solid;
      border-color: white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      color: white;
      border-color: #00ba4f;
      background: #00ba4f;
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }


  .ftc-radiobutton-secondary{
    position: absolute;
    width: 24px;
    height: 24px;
    color: black;
    border: 1px solid #dcdddd;
    border-radius: 25px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 7px;
      width: 8px;
      height: 14px;
      border-style: solid;
      border-color: white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      color: white;
      border-color: $color-primary;
      background: $color-primary;
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }      
    }    
  }

  .ftc-radiobutton-border-unchecked{
    width: max-content;
    height: 40px;
    padding: 7px 27px 9px 7px;
    border-radius: 20px;
    border: solid 1px #dcdddd;
    background-color: #ffffff;
    color: #444444
  }

  .ftc-radiobutton-border-checked{
  border-color: #a50808;
  color: #a50808;
  background-color:#fffafa;
  width: max-content;
  height: 40px;
  padding: 7px 27px 9px 7px;
  border-radius: 20px;  
}