.border-bottom {
  border-bottom: 1px solid lightgrey;
}

.border-left {
  border-left: 1px solid lightgrey;
}

@media (max-width: 991px) {
  .border-right {
    border-right: none !important;
    height: 100vh !important;
  }
}

.modal__row .border__right {
  border-right: 1px solid lightgrey !important;
  height: 100%;
}

.header-margin {
  margin-left: 6%;
}

.border-top-shadow {
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.08);
}

.border-0 {
  border-radius: 0;
}

.offline-card .MuiCardContent-root {
  background-color: #fffaf2;
}

.offline-white-bg {
  background-color: white !important;
}
