.label-primary {
  color: $label-green;
}

.label-alert {
  color: $label-yellow;
}

.label-error {
  color: $label-red;
}

.label-readonly {
  padding: 7px 16px;
  padding-bottom: 0;
  background-color: #f8f8f8;
}

.label-active {
  background-color: #e4f5eb;
  padding: 8px 12px 8px 12px;
  border-radius: 50px;
  margin-bottom: 0;
}

.label-inactive {
  background-color: #f5f5f5;
  padding: 8px 11px 8px 12px;
  border-radius: 50px;
  margin-bottom: 0;
}

.label-deny {
  background-color: #fff2f2;
  padding: 8px 11px 8px 12px;
  border-radius: 50px;
  margin-bottom: 0;
  color: #ff4646;
}

.filter-label {
  width: max-content;
  height: 32px;
  margin: 0 12px 12px 0;
  padding: 6px 8px 8px 14px;
  border-radius: 16px;
  background-color: #f2f2f2;
}

.label-pending {
  background-color: #fff3cd;
  padding: 8px 11px 8px 12px;
  border-radius: 50px;
}

.label-active-sm {
  background-color: #e4f5eb;
  padding-left: 9px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 4px;
  border-radius: 50px;
}

.label-pending-sm {
  background-color: #fff3cd;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 2px;
  padding-bottom: 4px;
  border-radius: 50px;
}

.label-deny-sm{
  background-color: #fff2f2;
  color: #ff4646;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 2px;
  padding-bottom: 4px;
  border-radius: 50px;
}

.rdt_TableCell label {
  margin-bottom: 0 !important;
}

.rdt_TableCell .label-active,
.rdt_TableCell .label-pending {
  margin-bottom: 0;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  min-width: 150px;
  color: black;
  font-size: 12px;
  background-color: #EFEFEF;
  padding: 2px 7px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#bottom {
  top: 35px;
  left: -60%;
}
#right {
  top: -8px;
  left: 120%;
}

.hover-text {
  position: relative;
  display: inline-block;
}